import React, { useState } from 'react';

import { useFeaturedBanners } from './utils/hooks/useFeaturedBanners';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './views/Home';

import './App.css';
import ProductList from './components/ProductList';

function App() {
  const { data, isLoading } = useFeaturedBanners();

  const [section, setSection] = useState('home');

  function navigateTo(section) {
    setSection(section);
  }

  return (
    <div className="App">
      <Header onClick={() => {navigateTo('home')}}/>
      <div className="App-content">
        {section === 'home' && <Home navigateTo={navigateTo}/>}
        {section === 'all-products' && <ProductList navigateTo={navigateTo}/>}
      </div>
      <Footer/>
    </div>
  );
}

export default App;
