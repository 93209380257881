function Sidebar({categories, onPickCategory}) {
    return (
        <div className="App-sidebar">
        <ul>
          {categories.map(cat =>
            (<li key={cat.id} onClick={() => onPickCategory(cat.id)}>
              <span className="cat-name">{cat.data.name}</span>
              <img src={cat.data.main_image.url} alt={cat.data.main_image.alt}/>
            </li>)
          )}
        </ul>
      </div>
    );
}

export default Sidebar;
