import React, { useEffect, useState } from 'react';
import sliderData from '../mocks/en-us/featured-banners.json';
import productCategoriesData from '../mocks/en-us/product-categories.json';
import productsData from '../mocks/en-us/featured-products.json';
import Product from '../components/Product';
import Banner from '../components/Banner';
import Sidebar from '../components/Sidebar';
import './Home.scss';

function Home({navigateTo}) {
  const [category, setCategory] = useState(null);
  const [currentBanner, setCurrentBanner] = useState(0);

  useEffect(() => {
    function setBanner() {
      if (sliderData.results.length <= currentBanner + 1) {
        setCurrentBanner(0);
      } else {
        setCurrentBanner(currentBanner + 1);
      }
    };
    const timeoutID = setTimeout(setBanner, 2000);
    return () => clearInterval(timeoutID);
  }, [currentBanner]);

  return (
      <div className="App-home">
        <Banner data={sliderData.results[currentBanner]}/>
        <Sidebar categories={productCategoriesData.results} onPickCategory={cat => setCategory(cat)}/>
        <ul className="App-products">
          {productsData.results.map(item =>
            (!category || category === item.data.category.id) && (<li key={item.id}>
              <Product data={item}/>
            </li>)
          )}
        </ul>
        <button onClick={() => {navigateTo('all-products')}}>
          View all products
        </button>
      </div>
  );
}

export default Home;
