function Product({data}) {
  return (
      <article className="App-product">
          {data.data.name}
          <br/>
          <img src={data.data.mainimage.url} alt={data.data.mainimage.alt}/>
          {/* {JSON.stringify(data)} */}
      </article>
  );
}

export default Product;
