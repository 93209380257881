import {useState} from 'react';

import Product from '../components/Product';

import productsData from '../mocks/en-us/products.json';
import productCategoriesData from '../mocks/en-us/product-categories.json';
import Sidebar from './Sidebar';

function ProductList({data}) {
  const [category, setCategory] = useState(null);

  return (
    <div className="App-home">
      <Sidebar categories={productCategoriesData.results} onPickCategory={cat => setCategory(cat)}/>
      <article className="App-product-list">
        <h1>This is the Product List Page</h1>
        <ul className="App-products">
          {productsData.results.map(item =>
            (!category || category === item.data.category.id) && (<li key={item.id}>
              <Product data={item}/>
            </li>)
          )}
        </ul>
      </article>
    </div>
  );
}

export default ProductList;
