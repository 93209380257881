import React from 'react';

function Footer() {
  return (
      <footer className="App-footer">
          <hr/>
          Ecommerce created during Wizeline’s Academy React Bootcamp
      </footer>
  );
}

export default Footer;
