import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import logo from './../logo.svg';
import './Header.scss';

function Header({onClick}) {
  return (
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" onClick={onClick}/>
        <div className="App-header-right-section">
          <input type="text" placeholder="Looking for something?"/>
          <button>
            <FontAwesomeIcon icon={faSearch}/>
          </button>
          <button>
            <FontAwesomeIcon icon={faShoppingCart}/>
          </button>
        </div>
      </header>
  );
}

export default Header;
