import './Banner.scss';

function Banner({data}) {
  return (
      <article className="App-banner">
        <span className="banner-name">{data.data.title}</span>
        <img src={data.data.main_image.url} alt={data.data.main_image.alt}/>
      </article>
  );
}

export default Banner;
